import {createStore, createEvent} from 'effector';

export const isAuthStore = createStore(null);
export const nameStore = createStore(null);
export const emailStore = createStore(null);

export const authEvent = createEvent();

export const logoutEvent = createEvent();

nameStore.on(authEvent, (state, payload) => {
    return payload.name;
});

emailStore.on(authEvent, (state, payload) => {
    return payload.email;
});

isAuthStore.on(authEvent, () => {
    return true;
})

nameStore.on(logoutEvent, () => null);
emailStore.on(logoutEvent, () => null);
isAuthStore.on(logoutEvent, () => false);
