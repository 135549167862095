import React from "react";
import { ReactTypeformEmbed } from 'react-typeform-embed';
import {Header} from "../header/Header";
import {useStore} from "effector-react";
import {emailStore, isAuthStore} from "../../stores/auth";
import {Heading} from "rebass";
import {Center} from "../center/Center";
import {Spinner} from "../spinner/Spinner";

const TYPEFORM_USER = process.env.TYPEFORM_USER;
console.log(TYPEFORM_USER);

export const Form = (props) => {
    const {params: {formId}} = props;
    const isAuth = useStore(isAuthStore);
    const email = useStore(emailStore);

    return <div>
        <Header />

        <div style={{position: "relative", height: "calc(100vh - 88px)"}}>
            {isAuth === true ? <ReactTypeformEmbed url={`https://${TYPEFORM_USER}.typeform.com/to/${formId}?email=${email}`}/> :
                isAuth === false ? <Center>
                <Heading>
                    Авторизуйтесь, чтобы проходить тесты
                </Heading>
                </Center> : <Center>
                    <Spinner />
                </Center>
            }
        </div>
    </div>
}
