import React from "react";
import {Flex, Text, Box, Image, Link as RebassLink, Button} from "rebass";
import logoImg from "./logo.svg";
import logoSmallImg from "./logoSmall.svg";
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import {authEvent, isAuthStore, logoutEvent, nameStore} from "../../stores/auth";
import {useStore} from "effector-react";
import {Link} from "wouter";
import googleIconImg from "./icons8-google.svg";

const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;

const AuthButton = (props) => {
    const {text, ...renderProps} = props;
    return <Button py={3} variant="secondary"
                   onClick={renderProps.onClick}
                   disabled={renderProps.disabled} sx={{
        display: "flex",
        alignItems: "center"
    }}>
        <Image height={24} mr={2} src={googleIconImg} sx={{display: "inline"}} /><span>{text}</span>
    </Button>
}

export const Header = () => {
    const name = useStore(nameStore);
    const isAuth = useStore(isAuthStore);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (!isAuth) {
                logoutEvent();
            }
        }, 3000);
        return () => clearTimeout(timeout);
    }, [isAuth]);

    const responseGoogle = (response) => {
        if (!response || !response.profileObj) {
            logoutEvent();
            return;
        }
        authEvent(response.profileObj);
    }

    const logout = () => {
        logoutEvent();
    }

    return <Flex
        px={[3, 4]}
        py={3}
        alignItems='center'>
        <Link to={"/"}>
            <Image height={48} src={logoImg} display={["none", "block"]} sx={{
                cursor: "pointer"
            }} />
            <Image height={48} src={logoSmallImg} display={["block", "none"]} sx={{
                cursor: "pointer"
            }} />
        </Link>
        <Box mx='auto' />
        {!name ? <Flex alignItems={"center"}>
            <RebassLink target="_blank"
                        href={"https://accounts.google.com/signup/v2/webcreateaccount?flowEntry=SignUp&flowName=GlifWebSignIn"}
                        fontSize={2}
                        cursor="pointer"
                        color='#737C95'
                        display={["none", "inline"]}
                        mr={3}>
                Регистрация
            </RebassLink>
            <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                render={renderProps => (
                    <AuthButton {...renderProps} text="Войти" />
                )}
                buttonText="Войти"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
            />
        </Flex> : <Flex alignItems={"center"}>
            <Text fontSize={2} color='#737C95' mr={3} display={["none", "inline"]}>
                {name}
            </Text>
            <GoogleLogout
                clientId={GOOGLE_CLIENT_ID}
                render={renderProps => (
                    <AuthButton {...renderProps} text="Выйти" />
                )}
                buttonText="Выйти"
                onLogoutSuccess={logout}
            >
            </GoogleLogout>
        </Flex>}
    </Flex>
}
