import React from "react";
import "react-notion/src/styles.css";
import { NotionRenderer } from "react-notion";
import useFetch from "react-fetch-hook";
import {Box, Flex} from "rebass";
import {Header} from "../header/Header";

const NOTION_CONFIDENCE_DOC_ID = process.env.NOTION_CONFIDENCE_DOC_ID;

export const Confidence = () => {
    const {data} = useFetch(`https://notion-api.splitbee.io/v1/page/${NOTION_CONFIDENCE_DOC_ID}`);
    return <>
        <Header />
        <Box p={[3, 5]}>
            <Flex flexDirection="column" alignItems="center">
                <Box p={[3, 5]} sx={{
                    maxWidth: "768px",
                    background: "#ffffff"
                }}>
                    {data && <NotionRenderer blockMap={data} />}
                </Box>
            </Flex>
        </Box>
    </>
}
