import React from "react";
import ReactDOM from 'react-dom'
import "regenerator-runtime/runtime.js";
import {App} from "./App";
import { ThemeProvider } from 'emotion-theming';

const theme = {
    colors: {
        text: '#000',
        background: 'black',
        primary: "linear-gradient(169.53deg, #E8E9EC 0%, #FFFFFF 100%)",
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700
    },
    lineHeights: {
        body: 1.5,
        heading: 1.25
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    fonts: {
        body: 'Open Sans, sans-serif',
        heading: 'Open Sans, sans-serif',
        monospace: 'Open Sans, monospace'
    },
    radii: {
        default: 12,
    },
    buttons: {
        primary: {
            fontSize: 2,
            fontWeight: 600,
            color: "#53BAE7",
            borderRadius: 24,
            background: "linear-gradient(169.53deg, #E8E9EC 0%, #FFFFFF 100%)",
            boxShadow: "5px 5px 13px rgba(220, 220, 221, 0.9), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px -5px 10px rgba(220, 220, 221, 0.2), -5px 5px 10px rgba(220, 220, 221, 0.2), inset -1px -1px 2px rgba(220, 220, 221, 0.5), inset 1px 1px 2px rgba(255, 255, 255, 0.3)",
            transition: "box-shadow .2s",
            "&:hover": {
                boxShadow: "9px 9px 23px rgba(198, 198, 199, 0.9), -9px -9px 18px rgba(255, 255, 255, 0.9), 9px -9px 18px rgba(198, 198, 199, 0.2), -9px 9px 18px rgba(198, 198, 199, 0.2), inset -1px -1px 2px rgba(198, 198, 199, 0.5), inset 1px 1px 2px rgba(255, 255, 255, 0.3)"
            }
        },
        secondary: {
            fontSize: 2,
            color: "#737C95",
            borderRadius: 24,
            background: "linear-gradient(169.53deg, #E8E9EC 0%, #FFFFFF 100%)",
            boxShadow: "5px 5px 13px rgba(220, 220, 221, 0.9), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px -5px 10px rgba(220, 220, 221, 0.2), -5px 5px 10px rgba(220, 220, 221, 0.2), inset -1px -1px 2px rgba(220, 220, 221, 0.5), inset 1px 1px 2px rgba(255, 255, 255, 0.3)",
            transition: "box-shadow .2s",
            "&:hover": {
                boxShadow: "9px 9px 23px rgba(207, 207, 209, 0.9), -9px -9px 18px rgba(255, 255, 255, 0.9), 9px -9px 18px rgba(207, 207, 209, 0.2), -9px 9px 18px rgba(207, 207, 209, 0.2), inset -1px -1px 2px rgba(207, 207, 209, 0.5), inset 1px 1px 2px rgba(255, 255, 255, 0.3)"
            }
        }
    }
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>,
    document.getElementById("root")
);
