import React from "react";
import {Box, Image} from "rebass";
import spinner from "./Spinner2x.webp";
import { css, keyframes } from 'emotion';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = () => {
    return <Image src={spinner} sx={{
        height: 64,
        width: 64,
        animation: `${rotate} 2s linear infinite`
    }} />
}
