import React from "react";
import { Switch, Route } from "wouter";
import {Form} from "./components/form/Form";

import {HomePage} from "./components/home/HomePage";
import {Confidence} from "./components/confidence/Confidence";

export const App = ()  => {

    return <>
        <Switch>
            <Route path="/" component={HomePage} />
            <Route path="/form/:formId" component={Form} />
            <Route path="/confidence" component={Confidence} />
        </Switch>
    </>
};
