import {Flex} from "rebass";
import React from "react";


export const Center = (props) => {
    return <Flex justifyContent="center" sx={{
        width: "100%"
    }}>
        {props.children}
    </Flex>
}
