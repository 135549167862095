import React from "react";
import {Header} from "../header/Header";
import {Box, Flex, Heading} from "rebass";
import {useStore} from "effector-react";
import {authEvent, emailStore, isAuthStore} from "../../stores/auth";
import {TestCard} from "../test-card/TestCard";
import {Footer} from "../footer/Footer";
import {getTests, testsStore} from "../../stores/tests";
import {Spinner} from "../spinner/Spinner";
import {Center} from "../center/Center";

export const HomePage = () => {
    const isAuth = useStore(isAuthStore);
    const tests = useStore(testsStore);

    React.useEffect(() => {
        getTests();
    }, []);

    return <Flex flexDirection={"column"} sx={{
        minHeight: "100vh"
    }}>
        <Header />

        <Box p={[3, 5]} flex={1}>
            {isAuth === true ? <Flex flexDirection={["column", "row"]} flexWrap={"wrap"}>
                {tests && tests.map((item) => <TestCard item={item} />)}
            </Flex> : isAuth === false ? <Center>
                <Heading>
                    Авторизуйтесь, чтобы проходить тесты
                </Heading>
            </Center> : <Center>
                <Spinner />
            </Center>}
        </Box>

        <Footer />
    </Flex>
}
