import {Link as WouterLink} from "wouter";
import {Button, Card, Image, Text} from "rebass";
import React from "react";
import {useStore} from "effector-react";
import {emailStore} from "../../stores/auth";
import {API_HOST} from "../../api";
import useFetch from "react-fetch-hook";
import {declOfNum} from "../../utils/declOfNum";

const countOfNumbers = declOfNum(["раз", "раза", "раз"])

export const TestCard = (props) => {
    const email = useStore(emailStore);
    const { item } = props;
    const previewImg = item.image && item.image.length > 0 && item.image[0].thumbnails.large.url;
    const {data: answersData} = useFetch(`${API_HOST}/api/answers?testId=${item.typeformId}&email=${email}`, {
        depends: [email]
    });
    const isAlreadyDone = answersData && answersData.count > 0;
    const type = item.type;

    return <WouterLink to={`/form/${item.typeformId}`}>
        <Card py={4} px={4} mr={[0, 4]} mb={4} sx={{
            borderRadius: 14,
            background: "linear-gradient(159.29deg, rgba(234, 235, 240, 0.8) -1.96%, rgba(242, 243, 247, 0.8) 100.81%)",
            boxShadow: "5px 5px 13px rgba(211, 212, 216, 0.9), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px -5px 10px rgba(211, 212, 216, 0.2), -5px 5px 10px rgba(211, 212, 216, 0.2), inset -1px -1px 2px rgba(211, 212, 216, 0.5), inset 1px 1px 2px rgba(255, 255, 255, 0.3);"
        }}>
            <Image width={["100%", 256]} src={previewImg} sx={{
                borderRadius: 10,
            }} />
            <Text width={256} mt={2} fontSize={4} color="#737C95" sx={{
                lineHeight: 1.2
            }}>{item.name}</Text>
            {isAlreadyDone && type !== "repeatable" && <Text mt={3} fontSize={2} fontWeight='300' color='#737C95'>Тест уже пройден</Text>}
            {isAlreadyDone && type === "repeatable" && <Text mt={3} fontSize={2} fontWeight='300' color='#737C95'>Пройден {answersData.count} {countOfNumbers(answersData.count)}</Text>}
            <Button mt={3} py={3} variant="primary" sx={{
                width: "100%"
            }}>{!isAlreadyDone ? "Пройти" : "Пройти еще раз"}</Button>
        </Card>
    </WouterLink>
}
