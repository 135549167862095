import React from "react";
import {Flex, Text, Box, Link} from "rebass";
import {Link as WouterLink} from "wouter";

export const Footer = () => {
    return <Flex
        px={[3, 4]}
        py={3}
        alignItems='center'
        flexWrap={"wrap"}
        sx={{opacity: 0.6}}>
        <Text fontSize={2} color='#737C95' mb={[3, 0]} mr={2}>
            © Additivka, {new Date().getFullYear()}
        </Text>
        <Box flex={1}/>
        <Text fontSize={1} color='#737C95' mb={[3, 0]} mr={2}>
            <Link as={WouterLink} sx={{color: '#737C95'}} to="/confidence">Политика конфиденциальности</Link>
        </Text>
        <Text fontSize={1} color='#737C95' mr={2} display={["none", "inline"]}>
            /
        </Text>
        <Text fontSize={1} color='#737C95' mb={[3, 0]} mr={2}>
            Разработка — <Link sx={{color: '#737C95'}} target="_blank" href="https://lessmess.agency/">lessmess</Link>
        </Text>
        <Text fontSize={1} color='#737C95' mr={2} display={["none", "inline"]}>
            /
        </Text>
        <Text fontSize={1} color='#737C95' mb={[3, 0]} mr={2}>
            Иллюстрации — <Link sx={{color: '#737C95'}} target="_blank" href="https://icons8.com/">icons8</Link>
        </Text>
    </Flex>
}
