import {createEffect, restore} from "effector";
import {API_HOST} from "../api";

export const getTests = createEffect({
    handler: async () => {
        const url = `${API_HOST}/api/tests`
        const req = await fetch(url)
        return req.json()
    }
});

export const testsStore = restore(getTests, []);
